import React, { Component } from "react";
import UserService from "../user/user.service";

export default class BoardAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: ""
        };
    }

    componentDidMount() {
        UserService.getAdminBoard().then(
            response => {
                this.setState({
                    content: response.data
                });
            },
            error => {
                this.setState({
                    content: (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString()
                });
            }
        );
    }

    render() {
        return (
            <div className="container">
                <header className="jumbotron">
                    {/* <h3>{this.state.content}</h3> */}
                    <h3>About board</h3>
                    <div>Environment variables: <strong>{process.env.REACT_APP_ENV}</strong></div>
                    <div>
                        URL LIST:<ul>
                            <li><strong>Auth</strong> = {process.env.REACT_APP_AUTH_API_URL}</li>
                            <li><strong>Pulse</strong> = {process.env.REACT_APP_PULSE_API_URL}</li>
                            <li><strong>Team Management</strong> = {process.env.REACT_APP_TEAMMANAGEMENT_API_URL}</li>
                            <li><strong>Invite</strong> = {process.env.REACT_APP_INVITE_API_URL}</li>
                            <li><strong>User Profile</strong> = {process.env.REACT_APP_USERPROFILE_API_URL}</li>
                            <li><strong>Feedback</strong> = {process.env.REACT_APP_FEEDBACK_API_URL}</li>
                            <li><strong>Notification</strong> = {process.env.REACT_APP_NOTIFICATION_API_URL}</li>
                            <li><strong>Streak</strong> = {process.env.REACT_APP_STREAK_API_URL}</li>
                            <li><strong>My Year In Review</strong> = {process.env.REACT_APP_MYYEARINREVIEW_API_URL}</li>
                        </ul>
                    </div>
                </header>
            </div>
        )
    }
}