export default function MoodReference(){

    return {
        awesome: {
            description:'awesome',
            value:'4'
        },
        happy: {
            description:'happy',
            value:'3'
        },
        medium: {
            description:'OK',
            value:'2'
        },
        unhappy: {
            description:'unhappy',
            value:'1'
        },
        bad: {
            description:'bad',
            value:'0'
        },
    }
}