import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InviteService from "./invite.service";
import { useParams } from 'react-router-dom';


export default function JoinTeam(props) {

    let { activationKey } = useParams();

    const [activationCode, setActivationCode] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [newAccountPassword, setAccountPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleActivationCodeChange = function (e) {
        setActivationCode(e.target.value);
    }
    const handleEmailChange = function (e) {
        setEmail(e.target.value);
    }
    const handleAccountPassword = function (e) {
        setAccountPassword(e.target.value);
    }
    const handleName = function (e) {
        setName(e.target.value);
    }

    const handleAccountActivation = function (e) {

        InviteService.joinByActivationCode(activationCode, email, name, newAccountPassword)
            .then(response => {
                //debugger;
                setErrorMessage(response.data.message);
                window.location.pathname = "/login";
            }
                , error => {
                    try {
                        //debugger;

                        setErrorMessage(error.response.data.message);
                    }
                    catch (e) {
                        setErrorMessage("!!!" + error.message);
                    }
                }
            );
    }

    useEffect(() => {
        if (activationKey === undefined || activationKey === "") return;
        try {
            let base64ToString = Buffer.from(activationKey, "base64").toString();
            var myObject = JSON.parse(base64ToString);
            setActivationCode(myObject.a);
            setEmail(myObject.e);
        }
        catch (e) {

        }
    }, [activationKey])

    return (
        <div className="container">
            <div style={{ width: '100%' }} >
                <div>
                    <h4>Team Invitation</h4>
                </div>
                <div >
                    <div className="widget" style={{ width: '40em' }}>

                        <div className="row" >
                            <div className="col text-left">
                                <TextField label="Invitation Code" onChange={handleActivationCodeChange} value={activationCode}></TextField>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-left">
                                <TextField fullWidth label="Invitation E-mail Address" onChange={handleEmailChange} value={email}></TextField>
                            </div>
                        </div>
                        <div>
                            <p><br /><strong>Note:</strong> E-mail address and Invitation code <b>must match</b> to the e-mail received.</p>
                        </div>
                        <div className="row" >
                            <div className="col text-left">
                                <TextField type="text" label="Your Name" onChange={handleName} value={name}></TextField>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col text-left">
                                <TextField type="password" label="Set your new Password" onChange={handleAccountPassword} value={newAccountPassword}></TextField>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col text-center">
                                <Button variant="contained" color="primary" onClick={handleAccountActivation}>Join the Team</Button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col text-left">
                                <div className="errorMessage"><br />{errorMessage}</div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
