import * as React from 'react';
import Button from '@mui/material/Button'
import UserService from "../user/user.service";
import AuthService from "../login/auth.service";
import { useNavigate } from "react-router-dom";
import './DisableAccount.css';


export default function DisableAccount() {

  let navigate = useNavigate();


  const handleClose = () => {
    navigate("/profile");
  };

  const handleDisableAccount = (e) => {

    UserService.deactivateAccount().then((response) => {
      if (response.status === 200) {
        AuthService.logout();
        //log out user
        navigate("/disabled");
      }
    }, error => {

      //provide information the account is already disbled
      //anything else tell something went wrong
    })

    // in case o success close the popup
  }

  return (
    <div>

      <div className=''>
        <div id="item" className="text-right">
          <strong>Disable Account</strong>
        </div>
        <div className='text-left'>
          <div id="item">
            <p><strong>Disable your account will:</strong></p>
          </div>
          <div id="item">
            <ul>
              1. Disable all the teams you are the owner.<br />
              2. Prevent you to login.
            </ul>
          </div>
        </div>
        <div>
          &nbsp;
        </div>
        <div id="item" className="warning-are-you-sure">
          <strong>Are you sure you want to continue?</strong>
        </div>
        <div id="item" className="text-center">
          <Button variant="contained" disableElevation color="primary" onClick={handleClose}>Disagree</Button>
          &nbsp;
          <Button onClick={handleDisableAccount} >
            Agree
          </Button>
        </div>
      </div>

    </div>
  );
}
