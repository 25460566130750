import React from "react";
import { Component } from "react";
import { Grid } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Button, TextField } from "@mui/material";

import './GiphySelector.css';

export default class GiphySelector extends Component {

    constructor(props) {
        super(props);
        this.handleGiphyTextChange = this.handleGiphyTextChange.bind(this);
        this.fetchGifs = this.fetchGifs.bind(this);
        this.searchGiphy = this.searchGiphy.bind(this);
        this.state = {
            search: '',
            gif: undefined,
            refreshKey: 1
        }
    }
    /**
     * 
     * @returns Call Giphy API to get some GIFs
     */
    fetchGifs() {
        // use @giphy/js-fetch-api to fetch gifs
        const gf = new GiphyFetch('vQkG7autqfxCHsYiIAw6cd8pPYNbIwww');

        // configure your fetch: fetch 10 gifs at a time as the user scrolls (offset is handled by the grid)
        return gf.search(this.state.search, { sort: 'relevant', lang: 'en', limit: 17, rating: 'r', type: 'gifs' });
    }

    /**
     * Giphy selected
     * @param {*} gif
     * @param {*} e 
     */
    gifClick(gif, e) {
        console.log("gif", gif);
        e.preventDefault();
        this.setState({ gif: gif });
        this.props.selectedGif(gif);
        this.closeWindow(e, true);
    }

    /**
     * Handle text change
     * @param {*} e 
     */
    handleGiphyTextChange(e) {
        this.setState({
            search: e.target.value
        });
    }
    /**
     * Search giphys
     */
    searchGiphy() {
        this.setState({ refreshKey: Date.now() });

    }

    /**
     * Close dialog window
     * @param {*} e 
     * @param {*} forceClose 
     */
    closeWindow(e, forceClose) {
        if ((e.target.className === 'searchBox') || (forceClose))
            this.props.closeWindow();
    }


    render() {
        const { refreshKey } = this.state;

        return (
            <div className="giphyComponent">

                {this.props.showGiphyWindow &&
                    <div className="searchBox" onClick={(e) => this.closeWindow(e)}>
                        <div className="widget centerContent">
                            <div className="searchAreaContainer">
                                <div>
                                    <TextField
                                        variant="standard"
                                        className="textBox"
                                        placeholder="Search GIPHY"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            multiline: false,
                                            maxLength: 255,
                                        }}
                                        margin="normal"
                                        onChange={this.handleGiphyTextChange}
                                        multiline
                                        value={this.state.search}
                                        InputLabelProps={{
                                            shrink: true
                                        }} />
                                </div>
                                <div>
                                    <Button variant="contained"
                                        fullWidth
                                        color="primary"
                                        size="medium"
                                        onClick={() => this.searchGiphy()}>Search</Button>
                                </div>
                            </div>
                            <div>
                                <Grid width={390} columns={3}
                                    fetchGifs={this.fetchGifs}
                                    key={refreshKey}
                                    onGifClick={(gif, e) => this.gifClick(gif, e)} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}