import React, { Component } from 'react';
import AuthService from "../login/auth.service"
import './Profile.css';
import DisableAccount from './DisableAccount';
import ChangePassword from './ChangePassword';
import SideBarMenu from '../components/SideBarMenu';
import ProfileData from './ProfileData.component';
import Notifications from './Notifications';
import { SideBarData } from '../components/SideBarData';


export default class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: AuthService.getCurrentUser(),
            loading: false,
            data: SideBarData,
            isOpen: false
        };
    }

    render() {
        const { currentUser } = this.state;
        const { data } = this.state;

        return (
            <div className="container">
                {currentUser !== undefined && currentUser.name !== undefined &&
                    <>
                        <div className="categoryDisplay">
                            <h4>User Profile </h4>
                        </div>
                        <div className='flex-container'>
                            <div className="col-md-* centerContent">
                                <SideBarMenu data={data} />
                            </div>
                            <div className="col-md-6 ">
                                <div className="widget changePassword">
                                    {window.location.pathname === "/profile" && <ProfileData />}
                                    {window.location.pathname === "/profile/notifications" && <Notifications />}
                                    {window.location.pathname === "/profile/disable" && <DisableAccount />}
                                    {window.location.pathname === "/profile/changePassword" && <ChangePassword />}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

        );
    }
}