import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';

import AuthService from "./login/auth.service";

import Login from "./login/login.component";
import ForgotPassword from "./login/forgot.component";
import ResetPassword from "./login/reset.component";

import Register from "./login/register.component";
import Header from './header/Header';
import Footer from './footer/Footer';
import AdminBoard from "./admin/board-admin.component";
import ActivateAccount from "./account/ActivateAccount";

/* USER PROFILE */
import Profile from "./user/profile.component";
import AccountDisabled from './user/AccountDisabled';

/* MOOD SPECIFIC */
import MyDashboard from "./mood/MyDashboard";
import MyMood from "./mood/MyMood";
import TeamsDashboard from "./mood/TeamsDashboard";

/* TEAM MANAGEMENT */
import TeamManagementDashboard from "./teammanagement/TeamManagementDashboard";
import JoinTeam from './teammanagement/JoinTeam';

/* TEAM SPECIFIC */
import TeamDashboard from './team/Dashboard';
import MyYearInReview from './yearinreview/MyYearInReview';


class App extends Component {

  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }


  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <div>

        <Header currentUser={currentUser}
          showModeratorBoard={showModeratorBoard}
          showAdminBoard={showAdminBoard} />

        <div className="container mt-3">

          <Routes>
            {/* USER PREFERENCES */}
            <Route exact path={"/profile"} element={<Profile />} />
            <Route exact path={"/profile/:section"} element={<Profile />} />


            {/* SAVE  MOOD*/}
            <Route exact path={"/daily/:notificationToken/:moodRecord/:moodDate"} element={<MyMood />} />
            <Route exact path={"/user"} element={<MyMood />} />
            {/* <Route path={"/user"} Component={MyMood} /> */}

            {/* USER DASHBOARD */}
            <Route exact path={"/mydashboard"} Component={MyDashboard} />


            {/* TEAM DASHBOARD */}
            <Route path={"/manageteams"} Component={TeamManagementDashboard} />
            <Route exact path={"/dashboard"} Component={TeamsDashboard} />
            <Route exact path={"/team/:teamid"} Component={TeamDashboard} />

            {/* USER MANAGEMENT */}
            <Route path={"/activate"} element={<ActivateAccount />} />
            <Route path={"/activate/:activationKey"} element={<ActivateAccount />} />

            <Route exact path={"/invite"} element={<JoinTeam />} />
            <Route exact path={"/invite/:activationKey"} element={<JoinTeam />} />

            <Route exact path={"/"} element={<Login />} />
            <Route exact path={"/home"} element={<Login />} />

            <Route exact path={"/login"} Component={Login} />
            <Route exact path={"/register"} Component={Register} />
            <Route path={"/forgot"} Component={ForgotPassword} />
            <Route exact path={"/reset/:tokenKey"} Component={ResetPassword} />
            <Route exact path={"/disabled"} Component={AccountDisabled}></Route>
            <Route exact path={"/myyearinreview"} Component={MyYearInReview}></Route>

            {/* ADMIN */}
            <Route path={"/admin"} Component={<AdminBoard />} />

          </Routes>
        </div>
        <div>
          <Footer/>
        </div>
      </div>

    )
  }

}

export default App;