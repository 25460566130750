import React, { useState } from 'react';
import AuthService from './auth.service';
import { useParams } from 'react-router-dom';
import { Button, TextField, Paper } from '@mui/material';
import { Buffer } from 'buffer';

const ResetPassword = () => {
    const { tokenKey } = useParams(); // Assuming tokenKey is a route parameter

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const onPasswordChange2 = (e) => {
        setPassword2(e.target.value);
    };

    const handleForgot = (e) => {
        e.preventDefault();

        if (password !== password2) {
            setMessage("Passwords don't match.");
            setLoading(false);
            return;
        }

        setLoading(true);
        setMessage('');

        let base64ToString = Buffer.from(tokenKey, "base64").toString();
        var myObject = JSON.parse(base64ToString);

        AuthService.resetPassword(myObject.e, password, myObject.a).then(response => {
            setLoading(false);
            setSuccess(true);
            setMessage(response.data.message);
        },
            error => {
                const resMessage =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(error.response?.status === 401 ?
                    "I'm sorry, I couldn't find that e-mail." :
                    resMessage);
            }
        );
    };

    return (
        <div className='container'>
            <div >
                <div className="col-md-4">
                </div>
                <Paper className="col-md-4 widget">
                    <form>
                        <div>
                            <h4>Reset Password</h4>
                        </div>

                        {!success && <div>
                            <div>
                                Please set your new password
                            </div>
                            <><br></br></>
                            <div>
                                <TextField
                                    required
                                    id="password"
                                    label="Password"
                                    type="password"
                                    fullWidth
                                    onChange={onPasswordChange}
                                    variant='outlined'
                                />
                            </div>
                            <><br /></>
                            <div>
                                <TextField
                                    required
                                    id="password2"
                                    label="Re-enter your password"
                                    type="password"
                                    fullWidth
                                    onChange={onPasswordChange2}
                                    variant='outlined'
                                />
                            </div>

                            <div>&nbsp;</div>


                            <div>
                                <Button type="submit"
                                    variant="contained" color="primary" fullWidth
                                    id="login"
                                    value="login"
                                    onClick={handleForgot}
                                    disabled={loading} className="btn btn-primary btn-block">Reset Password</Button>

                                {loading && (
                                    <div className="text-center">
                                        <div className="spinner-border spinner-border-grow-m" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>&nbsp;</div>
                        </div>
                        }

                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                    </form>
                </Paper>
                <div className="col-md-4">
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
