import * as d3 from "d3";
import "d3-time-format";
import React, { useCallback, useEffect } from "react";
import './MonthlyMoodChart.css';


const MonthlyMoodChart = (props) => {

  /*
  Get Mood Image
  */
  function getUrlForMood(d) {
    var mapper = {
      0: '/svg/mad.svg',
      1: '/svg/not%20happy.svg',
      2: '/svg/medium.svg',
      3: '/svg/happy.svg',
      4: '/svg/very%20happy.svg'
    }
    return mapper[d];
  }

  function getYAxisNames(d) {
    return "";
    // var mapper = {
    //   0: MoodReference().veryunpleasant.description,
    //   1: MoodReference().unhappy.description,
    //   2: MoodReference().medium.description,
    //   3: MoodReference().happy.description,
    //   4: MoodReference().awesome.description
    // }
    // return mapper[d]
  }

  /**
   * Create path for each type
   * @param {data} dataIn 
   * @param {type to find} labelToMatch 
   * @returns specific object
   */
  function mapMoodArray(dataIn, labelToMatch) {

    var arrayOut = [];

    dataIn.forEach((d, i,) => {
      if (labelToMatch === 'USER') {
        if (!isNaN(d.moodUser) && (d.moodUser !== null)) {

          arrayOut.push({ "moodValue": d.moodUser, "moodDate": d.moodDate });
        }
      }
      else {
        if (!isNaN(d.moodTeam) && (d.moodTeam !== null)) {
          arrayOut.push({ "moodValue": d.moodTeam, "moodDate": d.moodDate });
        }
      }
    });

    return arrayOut;
  }

  function getWeekDays(data) {
    return data;
  }

  /**
   * Obtem o eixo dos Xs
   */
  function getAxis(dataIn) {
    var arrayOut = [];
    dataIn.forEach((d, i,) => {
      arrayOut.push(d.moodDate);
    });
    return arrayOut;
  }

  /*
CHART FOR WEEKLY MOOD
 */
  //const createGraph = async () => {
  const createGraph = useCallback(async () => {
    //function createGraph() {
    const margin = { top: 40, right: 20, bottom: 40, left: 70 },
      width = 560 - margin.left - margin.right,
      height = 300 - margin.top - margin.bottom;

    const x = d3.scalePoint().range([0, width]);
    const y = d3.scaleLinear().range([height, 0]);

    const areaLine = d3.area()
      .curve(d3.curveMonotoneX)
      .x(function (d) { return x(d.moodDate) })
      // .y0(y(0))
      // .y1(function (d) { return y(d.moodValue) });
      .y(function (d) { return y(d.moodValue) });
      

    const svg = d3.select("#" + props.id).select(".monthlySvgChart")
      .attr("viewBox", "0 0 " + (width + margin.left + margin.right) + " " + (height + margin.top + margin.bottom))
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);


    if (props.data === null) return;

    if (props.userOnly !== true) {
      var teamData = mapMoodArray(props.data, 'TEAM');
    }

    var userData = mapMoodArray(props.data, 'USER');
    var horizontalAxis = getAxis(props.data);

    //datas do periodo
    x.domain(horizontalAxis).range([0, width]);
    y.domain([0, 4]).range([height, 0]);

    svg.append("path")
      .datum(userData)
      .attr("class", "dayPath")
      .attr("d", areaLine);

    if (props.userOnly !== true) {
      svg.append("path")
        .datum(teamData)
        .attr("class", "lastWeekPath")
        .attr("d", areaLine);
    }

    // horizontal labels
    svg.append("g")
      .attr("transform", `translate(-10,${height})`)
      .attr("class", "axisHorizontal")
      .call(
        d3.axisBottom(x)
          .tickValues(horizontalAxis)
          .tickFormat(getWeekDays)
      )
      .selectAll("text")
      .attr("transform", `rotate(-70)`)
      .style("font-size", 10)
      .style("fill", "#69a3b2");

    // vertical labels
    svg.append("g")
      .attr("class", "axisVertical")
      .call(
        d3.axisLeft(y)
          .tickValues([0, 1, 2, 3, 4])
          .tickFormat(getYAxisNames)
      ).selectAll(".tick").each(function (d, i) {
        d3.select(this)
          .append('image')
          .attr('xlink:href', getUrlForMood(i))
          .attr('x', 0 - 55)
          .attr('y', margin.bottom - 57)
          .attr('width', 32)
          .attr('height', 32);
      });

      // .selectAll("text")
      // .attr("transform", "translate(-10,-10)rotate(-45)")
      // .style("text-anchor", "end")
      // .style("font-size", 10)
      // .style("fill", "#69a3b2");


    // Vertical Legend
    svg.append("text")
      .attr("y", -25)
      .attr("x", -margin.left)
      .attr("class", "title")
      .text(props.chartTitle)
      .style("fill", "#69a3b2")
      .style("font-weight", "500");


    //Legend
    svg.append("circle").attr("cx", width).attr("cy", -33).attr("r", 6).style("fill", "blue");
    
    svg.append("text").attr("x", width - 60).attr("y", -33).text("You").style("font-size", "15px").attr("alignment-baseline", "middle");
    if (props.userOnly!==true){
      svg.append("circle").attr("cx", width).attr("cy", -17).attr("r", 6).style("fill", "#9ccdc1").style("opacity", ".2").style("stroke", "#000");
      svg.append("text").attr("x", width - 60).attr("y", -17).text("Team").style("font-size", "15px").attr("alignment-baseline", "middle");
    }
  }, [props.data, props.chartTitle, props.id, props.userOnly])

  useEffect(() => {
    //if (props.id !== undefined && props.id.length > 0 && props.data !== undefined && props.data != null) {
    if (props.data !== undefined && props.data != null) {

      createGraph();
    }
  }, [createGraph, props.data, props.chartTitle, props.id])
  return (

    <div>
      {props.id !== undefined && props.id.length > 0 &&
        <div className="monthlyPath" id={props.id}>
          <svg className="monthlySvgChart" />
        </div>
      }

      {/* HELP ME NOT TO FORGET ABOUT ID PROPERTY */}
      {((props.id === undefined || props.id.length === 0) &&
        <div>
          You need to set the property "id" to this Mood Chart component.
        </div>)}

      {/* HELP ME NOT TO FORGET ABOUT DATA PROPERTY */}
      {((props.data === undefined || props.data === null) &&
        <div>
          Loading chart information...
        </div>)}

    </div>

  )

}
export default MonthlyMoodChart;