import React, { Component } from 'react';
import UserService from "../user/user.service";
import './Profile.css';
import { TextField, Button } from '@mui/material';

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onPassword2Change = this.onPassword2Change.bind(this);
        this.onOldPasswordChange = this.onOldPasswordChange.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);

        this.state = {
            loading: false,
            oldPassword: "",
            password: "",
            password2: ""
        };
    }


    onPasswordChange(e) {
        this.setState({
            password: e.target.value
        });
    }

    onOldPasswordChange(e) {
        this.setState({
            oldPassword: e.target.value
        });
    }

    onPassword2Change(e) {
        this.setState({
            password2: e.target.value
        });
    }
    handleChangePassword(e) {
        e.preventDefault();

        if (this.state.password !== this.state.password2) {
            this.setState({
                message: "The new passwords don't match.",
                loading: false
            });
            return;
        }
        if ((!this.state.password.trim()) || (!this.state.oldPassword.trim())) {
            this.setState({
                message: "Password can't be empty.",
                loading: false
            });
            return;
        }

        UserService.changePassword(this.state.oldPassword, this.state.password).then((response) => {

            //please check your e-mail
            this.setState({
                loading: false,
                message: response.data.message
            });

        },
            error => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                if ((error !== undefined) && (error.response !== undefined) && (error.response.status !== undefined)) {
                    if (error.response.status === 401) {
                        this.setState({
                            loading: false,
                            message: "I'm sorry your current password doesn't match."
                        });
                    }
                    else {
                        this.setState({
                            loading: false,
                            message: resMessage
                        });
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        message: "Something went wrong..."
                    });
                }
            }
        );
    }

    render() {
        return (
            <div>
                <div className="text-right">
                    <strong>Change Password</strong>
                </div>
                <div>
                    <TextField
                        id="oldpassword"
                        label="Old password"
                        type="password"
                        variant='outlined'
                        fullWidth
                        onChange={this.onOldPasswordChange}
                    />
                </div>
                <><br/></>
                <div>
                    <TextField
                        id="password"
                        label="Password"
                        type="password"
                        variant='outlined'
                        fullWidth
                        onChange={this.onPasswordChange}
                    />
                </div>
                <><br/></>
                <div>
                    <TextField
                        id="password2"
                        label="Re-enter new password"
                        type="password"
                        variant='outlined'
                        fullWidth
                        onChange={this.onPassword2Change}
                    />
                </div>
                <div><br /></div>
                <div><Button id="changePassword"
                    variant="contained" color="primary" fullWidth
                    value="Change Passowrd"
                    onClick={this.handleChangePassword}
                    disabled={this.state.loading}
                    disableElevation
                    className="btn btn-primary btn-block">New Password</Button></div>
                {this.state.loading && (
                    <div className="text-center">
                        <div className="spinner-border spinner-border-grow-m" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}

                {this.state.message && (
                    <div className="form-group">
                        <div><br /></div>
                        <div className="alert alert-danger" role="alert">
                            {this.state.message}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}