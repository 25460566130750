import React, { useState, useEffect } from 'react';
import WeeklyMoodChart from "../components/WeeklyMoodChart";
import MonthlyMoodChart from "../components/MonthlyMoodChart";
import MoodService from "./mood.service";
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';

const TeamWidget = ({ teamid, teamname, teammembers, showdetails }) => {
    const [teamData, setTeamData] = useState(null);
    const [teamMonthlyDetails, setTeamMonthlyDetails] = useState(null);

    const [weekView, setWeekView] = useState(true);
    const [monthView, setMonthView] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTeamData = async () => {
            try {
                setIsLoading(true);
                setError(null);

                MoodService.getTeamLastWeekMood(teamid).then(
                    teamResponse => {
                        setTeamData(teamResponse.data);
                    },
                    error => { }
                );

            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        if (teamid) {
            fetchTeamData();
        }
    }, [teamid]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!teamData) {
        return <div>No team data available.</div>;
    }

    /*
    *  Change the view between day and 30 days
    */
    const toogleView = () => {

        setWeekView(!weekView);
        setMonthView(!monthView);

        //refresh monthly data
        if (!monthView) {
            // data already served
            // toggle chart on state
            if (teamMonthlyDetails !== null) {
                return;
            }
            MoodService.getTeamPeriodMood(teamid, 29).then(
                teamResponse => {
                    setTeamMonthlyDetails(teamResponse.data);
                },
                error => { }
            );
        }
    }

    /*
     * Call team details 
    */
    const teamDetails = (teamid) => {
        window.location.pathname = '/team/' + teamid;
    }

    return (
        <div>

            {weekView &&
                <WeeklyMoodChart
                    id={'tid' + teamid}
                    chartTitle={teamname + ' (members: ' + teammembers + ')'}
                    data={teamData}
                    membersCount={teammembers} />
            }
            {monthView &&
                <MonthlyMoodChart id={'monthlyId' + teamid}
                    chartTitle={teamname + ' (members: ' + teammembers + ') | 30 days'}
                    data={teamMonthlyDetails}
                    membersCount={teammembers} />
            }

            {!showdetails &&
                <div className="teamDaysViews">
                    <div className={weekView ? "activeDay" : "availableDay"} onClick={() => toogleView()}>7d</div>
                    <div className={monthView ? "activeDay" : "availableDay"} onClick={() => toogleView()}>30d</div>
                </div>
            }

            {showdetails &&
                <div className="row">
                    <div className="teamDaysViews">
                        <div className={weekView ? "activeDay" : "availableDay"} onClick={() => toogleView()}>7d</div>
                        <div className={monthView ? "activeDay" : "availableDay"} onClick={() => toogleView()}>30d</div>
                    </div>

                    <div className="manageTeamDetails">
                        <Button onClick={(e) => teamDetails(teamid)}>
                            <Icon className="manageTeamDetailsButton">groups</Icon>
                            <div className="manageTeamDetailsTitle">
                                &nbsp; details
                            </div>
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};

export default TeamWidget;