import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import MyYearInReviewService from '../yearinreview/myyearinreview.service';
import './HalfDougnutChart.css'
import { ReactComponent as Mad } from '../images/svg/mad.svg';
import { ReactComponent as NotHappy } from '../images/svg/not happy.svg';
import { ReactComponent as Medium } from '../images/svg/medium.svg';
import { ReactComponent as Happy } from '../images/svg/happy.svg';
import { ReactComponent as VeryHappy } from '../images/svg/very happy.svg';

const HalfDoughnutChart = () => {

    //const [content, setContent] = useState(null);
    const [data, setData] = useState(null);
    const [averageMood, setAverageMood] = useState('');

    const dataset = [
        { moodLabel: 'Bad', count: 0,mood:0 },
        { moodLabel: 'Unhappy', count: 0,mood:1 },
        { moodLabel: 'OK', count: 0,mood:2 },
        { moodLabel: 'Happy', count: 0,mood:3 },
        { moodLabel: 'Awesome', count: 0,mood:4 }
    ];

    function calculateAverageMood(moods) {
        let totalMoodValue = 0;
        let totalCount = 0;
    
        moods.forEach(moodEntry => {
            totalMoodValue += moodEntry.mood * moodEntry.count;
            totalCount += moodEntry.count;
        });
    
        if (totalCount === 0) {
            return "No mood data available";
        }
    
        const averageMoodValue = totalMoodValue / totalCount;
    
        // Find the closest mood category based on the average mood value
        const closestMood = moods.reduce((prev, curr) => {
            return (Math.abs(curr.mood - averageMoodValue) < Math.abs(prev.mood - averageMoodValue) ? curr : prev);
        });
    
        return closestMood;
    }

    const getIcon = (x) => {
        switch (x) {
            case 0: return <Mad />;
            case 1: return <NotHappy />;
            case 2: return <Medium />;
            case 3: return <Happy />;
            case 4: return <VeryHappy />;
            default: return '';
        }
    }

    useEffect(() => {

        MyYearInReviewService.getMonthlyCount().then(
            response => {

                let _content = response.data;
                _content.map(response => {
                    return dataset[response.mood].count = response.count;
                })
                setAverageMood(calculateAverageMood(dataset));

                setData({
                    labels: dataset.map(item => `${item.moodLabel}`),
                    datasets: [
                        {
                            label: ' Count',
                            data: dataset.map(item => item.count),
                            backgroundColor: ['#ee2400', '#6699CC', '#F5F5DC', '#FFFF00', '#00FF00'],
                            hoverOffset: 4
                        },
                    ],
                });

            },
            error => {
            }
        );
        // eslint-disable-next-line
    }, []);



    const options = {
        circumference: 180,
        rotation: -90,
        cutout: '65%',
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return <>
        {data && averageMood.moodLabel&&
            <div>
                <div>
                    <Doughnut data={data} options={options} />
                </div>
                <div className='averageIcon'>{getIcon(averageMood.mood)}</div>
                <div className='monthlyMood'>{averageMood.moodLabel.toUpperCase()}</div>
            </div>
        }
    </>;
};

export default HalfDoughnutChart;
