import React, { Component } from "react";
import './Team.css';
import TeamMembersList from './TeamMembersList';
import EditTeamDetails from './EditTeamDetails';
import GetListOfTeams from './GetListOfTeams';
import CreateTeam from './CreateTeam';



export default class TeamManagementDashboard extends Component {

    constructor(props) {
        super(props);
        this.action = this.action.bind(this);

        this.state = {
            message: "",
            teamDescription: "",
            teamName: "",
            teamId: -1,
            refreshToken: new Date().toLocaleString(),
            selectedTeam: null,
            showTeamList: true,
            showTeamCreation: false,
            showEditTeamDetails: false,
            showTeamMembersList: false
        };
    }

    componentDidMount() {

    }


    action(action) {

        if (action.teamId !== undefined)
            this.setState({ teamId: action.teamId });

        if (action.teamName !== undefined)
            this.setState({ teamName: action.teamName });

        if (action.teamDescription !== undefined)
            this.setState({ teamDescription: action.teamDescription });

        if (action.refresh !== undefined)
            this.setState({ refreshToken: new Date().toLocaleString() });

        if (action.nextAction !== undefined) {
            switch (action.nextAction) {
                case 'createTeam':
                    this.setState(
                        {
                            showTeamList: false,
                            showTeamCreation: true,
                            showTeamMembersList: false,
                            showEditTeamDetails: false,
                        });
                    break;
                case 'memberList':
                    this.setState(
                        {
                            showTeamList: false,
                            showTeamCreation: false,
                            showTeamMembersList: true,
                            showEditTeamDetails: false,
                        });
                    break;
                case 'editTeam':
                    this.setState(
                        {
                            showTeamList: false,
                            showTeamCreation: false,
                            showTeamMembersList: false,
                            showEditTeamDetails: true,
                        });
                    break;
                case 'listTeams':
                    this.setState(
                        {
                            showTeamList: true,
                            showTeamCreation: false,
                            showTeamMembersList: false,
                            showEditTeamDetails: false,
                            showInviteTeamMember: false
                        });
                    break;
                default:
                    break;
            }
        }
    }

    render() {
        const { showTeamList, showTeamCreation, showEditTeamDetails, showTeamMembersList } = this.state;

        return (
            <div className="container">



                {showTeamList &&
                    <GetListOfTeams
                        refreshToken={this.state.refreshToken}
                        action={this.action}
                    />
                }

                {showEditTeamDetails && this.state.teamId > -1 &&
                    <EditTeamDetails
                        teamId={this.state.teamId}
                        teamName={this.state.teamName}
                        teamDescription={this.state.teamDescription}
                        action={this.action} />
                }

                {showTeamMembersList && this.state.teamId > -1 &&
                    <TeamMembersList
                        teamId={this.state.teamId}
                        teamName={this.state.teamName}
                        action={this.action} />
                }

                {showTeamCreation &&
                    <CreateTeam action={this.action} />
                }

            </div>
        )
    }
}