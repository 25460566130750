import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_AUTH_API_URL + '/test/';
const API_URL_USERPROFILE = process.env.REACT_APP_AUTH_API_URL + '/userprofile/v1';


    function getPublicContent() {
        return axios.get(API_URL + 'all');
    }

    function getUserBoard() {
        return axios.get(API_URL + 'user', { headers: authHeader() });
    }

    function getModeratorBoard() {
        return axios.get(API_URL + 'mod', { headers: authHeader() });
    }

    function getAdminBoard() {
        return axios.get(API_URL + 'admin', { headers: authHeader() });
    }

    function changePassword(oldPassword, newPassword) {
        return axios.post(API_URL_USERPROFILE + "/changePassword", {
            "o": oldPassword,
            "p": newPassword
        }, { headers: authHeader() });
    }
    
    function deactivateAccount() {
        return axios.post(process.env.REACT_APP_USERPROFILE_API_URL + "/userdetails/v1/deactivateAccount", {
            
        }, { headers: authHeader() });
    }

const UserService = {
    getPublicContent,
    getUserBoard,
    getModeratorBoard,
    getAdminBoard,
    changePassword,
    deactivateAccount
};

export default UserService;
