import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_STREAK_API_URL + '/streak/v1';

async function getStreak() {
    return axios.get(API_URL + '/getStreak',
        {
            headers: authHeader()
        });
}

const StreakService = {
    getStreak
};

export default StreakService;