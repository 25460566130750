import axios from "axios";
import { jwtDecode } from 'jwt-decode';
const API_URL = process.env.REACT_APP_AUTH_API_URL + "/auth";

//I've made a change so that login could be async
//https://bezkoder.com/react-jwt-auth/
async function login(email, password) {
    const response = await axios
        .post(API_URL + "/signin", {
            email,
            password
        });
    if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
}



function register(name, email, password) {
    return axios.post(API_URL + "/signup", {
        name,
        email,
        password,
        role: ['mod', 'user']
    });
}

function forgotPassword(email) {
    return axios.post(API_URL + "/forgotPassword", {
        "e": email
    });
}

function resetPassword(email, password, tokenKey) {
    return axios.post(API_URL + "/resetPassword", {
        "e": email,
        "t": tokenKey,
        "p": password
    });
}

function logout() {
    localStorage.removeItem("user");
}

function isSessionValid() {
    const token = JSON.parse(localStorage.getItem('user'));
    if (token != null) {
        var jwt_token = jwtDecode(token.accessToken);
        if (jwt_token.exp * 1000 < new Date().getTime()) {
            this.logout(); // remove token
        }
        else {
            return true;
        }
    }
    return false;
}

function getCurrentUser() {
    if (this.isSessionValid())
        return JSON.parse(localStorage.getItem('user'));
    return undefined;

}

const AuthService = {
    login,
    register,
    forgotPassword,
    resetPassword,
    logout,
    isSessionValid,
    getCurrentUser

}
export default AuthService;