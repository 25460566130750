import React, { Component } from "react";
import './MyYearInReview.css';
import Calendar from './Calendar';
import MyYearInReviewService from "./myyearinreview.service.js";

import AuthService from "../login/auth.service";

export default class MyYearInReview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content: "",
            data: null,
            teamList: [],
            loading: true,
            showWeekly: true,
            showMonthly: false,
            monthlyDetails: null,
            moodData: [],
            selectedYear: new Date().getFullYear(),
            availableData: []
        }
    }

    componentDidMount() {

        if (!AuthService.isSessionValid()) {
            window.location.pathname = '/login';
        }

        this.getMyYearInReview(this.state.selectedYear);
        this.getAvailableYears();


    }

    /**
     * Get all the available years from a user
     */
    getAvailableYears() {
        /*
            Get your own mood
            */
        MyYearInReviewService.getAvailableYears().then(
            response => {
                this.setState({ availableData: response.data });

            },
            error => {
                this.setState({ availableData: [] });
            }
        );
    }

    /**
     * Get the details for a specific year
     * @param {int} year 
     */
    getMyYearInReview(year) {

        MyYearInReviewService.getMyYearInReview(year).then(
            response => {
                this.setState({ moodData: response.data });

            },
            error => {
                this.setState({ moodData: [] });
            }
        );
    }

    decreaseYear() {
        this.setState({ selectedYear: this.state.selectedYear - 1 });
        this.getMyYearInReview(this.state.selectedYear - 1);
    }
    increaseYear() {
        this.setState({ selectedYear: this.state.selectedYear + 1 });
        this.getMyYearInReview(this.state.selectedYear + 1);
    }

    render() {

        return (
            <div>
                <div className="categoryDisplay">
                    <h4>
                        Year Review
                    </h4>
                </div>
                <div className="myYearInReviewAvailableYears">
                    <>{this.state.availableData.find(x => { return x.date < this.state.selectedYear }) && <div className="myYearInReviewPointer" onClick={() => this.decreaseYear()}>&lt;&nbsp;</div>}</>
                    {this.state.selectedYear}
                    <>{this.state.availableData.find(x => { return x.date > this.state.selectedYear }) && <div className="myYearInReviewPointer" onClick={() => this.increaseYear()}>&nbsp; &gt;</div>}</>
                </div>
                <div className="mainContainer">
                    <Calendar moodData={this.state.moodData} year={this.state.selectedYear} />
                </div>

            </div>
        )
    }
}