import axios from "axios";

const API_URL = process.env.REACT_APP_PULSE_API_URL + '/daily/v1';


function sendMood(notificationToken, moodRecord, moodReason, moodDate) {
    var message = "";
    // when a reson is specified for that mood
    if (moodReason !== undefined && moodReason.length > 0)
        message = { "mood": moodRecord, "moodToken": notificationToken, "moodReason": moodReason,"moodDate":moodDate };
    else
        message = { "mood": moodRecord, "moodToken": notificationToken,"moodDate":moodDate };
    return axios.post(API_URL + '/registerDailyMood', message);
}

const DailyNotificationService = {
    sendMood
}
export default DailyNotificationService;