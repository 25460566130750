import React, { Component } from 'react';
import AuthService from "../login/auth.service"
import './ProfileData.css';

export default class ProfileData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: AuthService.getCurrentUser(),
        };
    }
    getRole(role) {
        switch (role) {
            case "ROLE_MODERATOR": return "Moderator";
            default:
                return "User";
        }
    }

    render() {
        const { currentUser } = this.state;
        return (
            <div className=''>
                <div id="item" className="text-right">
                    <strong>User Profile</strong>
                </div>
                <div id="item" className="row">
                    <div id="categoryName"><strong>Name: </strong></div>
                    <div id="description">{currentUser.name}</div>
                </div>

                <div id="item" className="row">
                    <div id="categoryName"><strong>E-mail: </strong></div>
                    <div id="description">{currentUser.email}</div>
                </div>

                <div id="item" className="row">
                    <div id="categoryName"><strong>Roles: </strong></div>
                    <div id="description">
                        {currentUser.roles &&
                            currentUser.roles.map((role, index) =>
                                <div className="memberRoleList" key={index}>{this.getRole(role)}</div>
                            )}
                        </div>
                </div>

            </div>
        )
    }
}