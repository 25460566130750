import React, { Component } from 'react';
import './Login.css';
import AuthService from './auth.service';
import { TextField, Button } from '@mui/material';


export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.handleForgot = this.handleForgot.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);

        this.state = {
            email: "",
            password: "",
            loading: false,
            message: ""
        };
    }

    onEmailChange(e) {
        this.setState({
            email: e.target.value
        });
    }

    onPasswordChange(e) {
        this.setState({
            password: e.target.value
        });
    }

    handleForgot(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
        });

        AuthService.forgotPassword(this.state.email).then(response => {
            // this.props.history.push("/user");
            // window.location.reload();

            //please check your e-mail
            this.setState({
                loading: false,
                message: response.data.message
            });
        },
            error => {
                //const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                if ((error !== undefined) && (error.response !== undefined) && (error.response.status !== undefined)) {
                    if (error.response.status === 401) {
                        this.setState({
                            loading: false,
                            message: "I'm sorry, I couldn't find that e-mail."
                        });
                    }
                    else {
                        this.setState({
                            loading: false,
                            message: error.response.data.message
                        });
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        message: "Something went wrong..."
                    });

                }


            }
        );
    }

    render() {
        return (
            <div className='widget'>



                <div className="loginComponent">
                    <div className='loginComponentSize'>
                        <div className='backgroundImage' />
                        <div className='text-center'>
                            <span className="crankyName">Forgot Password</span>
                        </div>

                        <form>

                            <div>
                                <TextField
                                    required
                                    id="email"
                                    label="E-mail"
                                    type="email"
                                    variant='outlined'
                                    fullWidth
                                    onChange={this.onEmailChange}
                                />
                            </div>

                            <div>&nbsp;</div>


                            <div >
                                <Button type="submit"
                                    variant="contained" color="primary" fullWidth
                                    id="login"
                                    value="login"
                                    onClick={this.handleForgot}
                                    disabled={this.state.loading} className="btn btn-primary btn-block">Reset Password</Button>

                                {this.state.loading && (
                                    <div className="text-center">
                                        <div className="spinner-border spinner-border-grow-m" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>&nbsp;</div>

                            {this.state.message && (
                                <div className="form-group text-center">
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.message}
                                    </div>
                                </div>
                            )}
                        </form>

                    </div>
                </div>

            </div>
        );
    };
}