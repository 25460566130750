import React, { Component } from "react";
import './MembersComponent.css';
import Avatar from '@mui/material/Avatar';

export default class MembersComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            members: props.membersList
        }
    }
    componentDidMount() {

    }
    getFirstCharEachWord(name) {
        var str = name.split(' ').map(function (item) { return item[0] }).join('');

        return str;
    }

    render() {
        const members = this.props.membersList;
        return (
            <div className="members">
                <div><h4 className="membersTitle">Members ({members.length}):</h4></div>
                <div className="flex-membersContainer">
                    {members.map(member =>
                        <div key={member.name} className="membersContainer">
                            <div className="membersFloatChild"><Avatar className="membersAvatar">{this.getFirstCharEachWord(member.name)}</Avatar></div> 
                            <div className="membersFloatChild">{member.name}</div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}