import React, { Component } from 'react';
import './Login.css';
import Form from 'react-validation/build/form';
import CheckButton from "react-validation/build/button";
import { isEmail } from 'validator';
import {  TextField } from "@mui/material";

import AuthService from './auth.service';


export default class Register extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            name: "",
            email: "",
            password: "",
            successful: false,
            loading: false,
            message: ""
        };
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    handleRegister(e) {
        e.preventDefault();
        this.setState({
            message: "",
            successful: false,
            loading: true
        });

        if (this.state.name === "" || this.state.password === "" || this.state.email === "") {
            this.setState({
                message: "Sorry name, e-mail and password are mandatory fields.",
                loading: false
            });
            return;
        }

        if (!isEmail(this.state.email)) {
            this.setState({
                message: "Sorry e-mail is not in a valid format.",
                loading: false
            });
            return;
        }

        if (this.checkBtn.context._errors.length === 0) {
            AuthService.register(
                this.state.name,
                this.state.email,
                this.state.password
            )
                .then(
                    response => {
                        this.setState({
                            message: response.data.message,
                            successful: true,
                            loading: false
                        });
                    },
                    error => {
                        const resMessage = (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();

                        this.setState({
                            successful: false,
                            loading: false,
                            message: resMessage
                        });
                    }
                );
        }
    }

    render() {
        return (
            <div className='widget'>

                <div className="loginComponent">
                    <div className='loginComponentSize'>
                        <div className='backgroundImage' />
                        <div className='text-center'>
                            <span className="crankyName">Sign Up</span>
                        </div>
                        <Form>
                            {!this.state.successful && (
                                <>
                                    <div>
                                        <TextField
                                            required
                                            id="name"
                                            label="Your name"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            inputProps={{ maxLength: 255 }}
                                            onChange={this.onChangeName}
                                        ></TextField>
                                    </div>
                                    <div>
                                        <TextField
                                            required
                                            id="email"
                                            label="E-mail"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            inputProps={{ maxLength: 255 }}
                                            onChange={this.onChangeEmail}
                                        ></TextField>
                                    </div>
                                    <div>
                                        <TextField
                                            required
                                            id="password"
                                            label="Password"

                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            inputProps={{ maxLength: 255 }}
                                            onChange={this.onChangePassword}
                                            type="password"
                                            autoComplete="current-password"
                                        ></TextField>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            id="signup"
                                            variant="contained" color="primary" fullWidth
                                            className="btn btn-primary btn-block"
                                            onClick={this.handleRegister}
                                            disabled={this.state.loading}>Sign Up</button>

                                    </div>
                                    <div>
                                        {/* By creating an account, you accept CrankyDino */}
                                        By creating an account, you accept CrankyDino's <a href="https://www.crankydino.com/terms-of-service/" target="_blank" rel="noreferrer noopener">terms of service</a>.
                                    </div>


                                    {this.state.loading && (
                                        <div className="text-center">
                                            <div className="spinner-border spinner-border-grow-m" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {this.state.message && (
                                <div className="form-group">
                                    <br></br>
                                    <div className={
                                        this.state.successful
                                            ? "alert alert-success"
                                            : "alert alert-danger"
                                    }
                                        role="alert">
                                        {this.state.message}
                                    </div>
                                </div>
                            )}
                            <CheckButton
                                style={{ display: "none" }}
                                ref={c => { this.checkBtn = c; }} />
                        </Form>
                    </div>
                </div>
            </div>

        );
    }
}