import axios from "axios";

const API_URL = process.env.REACT_APP_AUTH_API_URL + "/auth";

//I've made a change so that login could be async
//https://bezkoder.com/react-jwt-auth/
async function activateAccount(activationKey, email) {
    return await axios
        .post(API_URL + "/activation", {
            a: activationKey,
            e: email
        });
}

const ActivationService = {
    activateAccount
}
export default ActivationService;