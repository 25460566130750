import React, { Component } from 'react';
import './Login.css'

import AuthService from './auth.service';
import { TextField, Button } from '@mui/material';
import { Link } from "react-router-dom";


export default class Login extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);

        this.state = {
            email: "",
            password: "",
            loading: false,
            message: ""
        };
    }

    onEmailChange(e) {
        this.setState({
            email: e.target.value
        });
    }

    onPasswordChange(e) {
        this.setState({
            password: e.target.value
        });
    }

    showDashboard() {
        window.location.pathname = '/mydashboard';
    }
    componentDidMount() {
        //is ticket still available?
        if (AuthService.isSessionValid()) {
            this.showDashboard();
        }

    }

    handleLogin(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
        });

        AuthService.login(this.state.email, this.state.password).then(() => {
            this.showDashboard();
        },
            error => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                if ((error !== undefined) && (error.response !== undefined) && (error.response.status !== undefined)) {
                    if (error.response.status === 401) {
                        this.setState({
                            loading: false,
                            message: "I'm sorry, I couldn't find that e-mail or password."
                        });
                    }
                    else {
                        this.setState({
                            loading: false,
                            message: resMessage
                        });
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        message: "Something went wrong..."
                    });
                }
            }
        );
    }

    render() {
        return (
            <div className='widget'>

                <div className="loginComponent">


                    <div className='loginComponentSize'>

                        <div className='backgroundImage' />

                        <div className='text-center'>
                            <span className="crankyName">Welcome back</span>
                        </div>


                        <form>

                            <div>
                                <TextField
                                    required
                                    id="email"
                                    label="E-mail address"
                                    type="email"
                                    variant='outlined'
                                    fullWidth
                                    onChange={this.onEmailChange}
                                />
                            </div>
                            <><br /></>
                            <div>
                                <TextField
                                    required
                                    id="password"
                                    label="Password"
                                    type="password"
                                    fullWidth
                                    onChange={this.onPasswordChange}
                                    variant='outlined'
                                />
                            </div>
                            <div>&nbsp;</div>

                            <div>


                                <div className='text-left'>
                                    <Link to={"/forgot"}>Forgot password?</Link>
                                </div>

                                <div>&nbsp;</div>

                                <Button
                                    type="submit"
                                    variant="contained" color="primary" fullWidth
                                    id="login"
                                    value="login"
                                    onClick={this.handleLogin}
                                    disabled={this.state.loading} className="btn btn-primary btn-block">Login</Button>

                                {this.state.loading && (
                                    <div className="text-center">
                                        <div className="spinner-border spinner-border-grow-m" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )}

                                <div>Don't have an account? <Link to={"/register"}>Sign up</Link></div>
                            </div>


                            {this.state.message && (
                                <div>
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.message}
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>

                </div>

            </div>
        );
    };
}