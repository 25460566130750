import React, { Component } from 'react';
import Switch from '@mui/material/Switch';
import { withStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import NotificationService from "../user/notification.service";
import TextField from '@mui/material/TextField';
import './Notifications.css';

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: '#ffffff',
            '& + $track': {
                opacity: 1,
                backgroundColor: '',
                borderColor: '#cacaca !important',
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#ffffff',
    },
    checked: {},
}))(Switch);

export default class Notifications extends Component {

    constructor(props) {
        super(props);
        this.handleWeekendChanges = this.handleWeekendChanges.bind(this);
        this.handleDailyNotificationChange = this.handleDailyNotificationChange.bind(this);
        this.handleHourChange = this.handleHourChange.bind(this);

        this.state = {
            details: {
                active: false,
                weekends: false,
                time: ""
            }
        }

    }
    componentDidMount() {
        NotificationService.getNotificationDetails()
            .then(
                response => {
                    this.setState({ details: response.data });
                },
                error => {

                }
            );
    }

    handleDailyNotificationChange(event) {
        this.setState({ details: { ...this.state.details, active: event.target.checked } });
        NotificationService.setNotificationStatus({ active: event.target.checked }).then(
            response => { },
            error => { }
        );
    }
    handleWeekendChanges(event) {
        this.setState({ details: { ...this.state.details, weekends: event.target.checked } });
        NotificationService.setWeekendStatus({ weekends: event.target.checked }).then(
            response => { },
            error => { }
        );
    }
    handleHourChange(event) {
        event.preventDefault();
        this.setState({ details: { ...this.state.details, time: event.target.value } });
        NotificationService.setNotificationHour({ time: event.target.value }).then(
            response => { },
            error => { }
        );
    }

    render() {
        const { details } = this.state;

        return (
            <div class="responsive-table">
                <div id="item" className="text-right">
                    <strong>Notifications<br /><br /></strong>
                </div>

                <div id="item" className="row">
                    <div className='column' >Daily Notifications:</div>
                    <div className='column'>
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>Off</Grid>
                            <Grid item>
                                <AntSwitch checked={details.active} onChange={this.handleDailyNotificationChange} name="checkedA" />
                            </Grid>
                            <Grid item>On</Grid>
                        </Grid>
                    </div>
                </div>

                <div id="item" className="row">
                    <div className='column'>Notification Hour (GMT):</div>
                    <div className='column'>
                        <TextField
                            id="time"
                            type="time"
                            value={details.time}
                            onChange={this.handleHourChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                        />
                    </div>
                </div>


                <div id="item" className="row">
                    <div className='column'>Weekends Notifications:</div>
                    <div className='column'>
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>Off</Grid>
                            <Grid item>
                                <AntSwitch checked={details.weekends} onChange={this.handleWeekendChanges} name="checkedWeekends" />
                            </Grid>
                            <Grid item>On</Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}