import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartBroken,faUser,faBell,faKey } from '@fortawesome/free-solid-svg-icons'

export const SideBarData =[
    {
        title:"User Profile",
        icon:<FontAwesomeIcon icon={faUser} />,
        link:"/profile",

    },
    {
        title:"Notifications",
        icon:<FontAwesomeIcon icon={faBell} />,
        link:"/profile/notifications"
    },
    {
        title:"Change Password",
        icon:<FontAwesomeIcon icon={faKey} />,
        link:"/profile/changePassword",

    },
    {
        title:"Disable Account",
        icon:<FontAwesomeIcon icon={faHeartBroken} />,
        link:"/profile/disable",

    },
]