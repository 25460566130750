import React, { Component } from 'react';
import './Profile.css';

export default class AccountDisabled extends Component {
    render() {
        return (
            <div className='disabledScreenMain'>
                <div className='disabledScreen'>
                    <div>
                        <img alt="Sad dino" src="/images/saddino.jpg" />
                    </div>
                    <div className='disabledScreenText'>
                        We are sad to see you go... 
                        Hope you'll be back.
                    </div>

                </div>
            </div>
        )
    }
}