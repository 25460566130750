import React, { Component } from "react";
import './DashboardChart.css';
import MoodService from "../mood/mood.service";
import WeeklyMoodChart from "../components/WeeklyMoodChart";
import MonthlyMoodChart from "../components/MonthlyMoodChart";
import MyTimelineComponent from "./PrivateFeedback/MyTimelineComponent";
import MyStreakdWidgets from "../streak/MyStreakWidgets";
import MyYearInReviewWidget from "../yearinreview/MyYearInReviewWidget";

import AuthService from "../login/auth.service";

export default class MyDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: "",
            data: null,
            teamList: [],
            loading: true,
            showWeekly: true,
            showMonthly: false,
            monthlyDetails: null
        }
    }
    selectIndividual() {
        let showWeekly = !this.state.showWeekly;
        let showMonthly = !this.state.showMonthly;


        //refresh weekly data
        if (showWeekly) {
            this.setState({ showWeekly, showMonthly });
        }

        //refresh monthly data
        if (showMonthly) {
            MoodService.getPeriodMood(29).then(
                response => {
                    let monthlyDetails = response.data;
                    this.setState({ showWeekly, showMonthly, monthlyDetails });
                },
                error => { }
            );
        }
    }

    selectDay(team) {

        let teamListProps = [...this.state.teamList];
        let amendTeam = teamListProps.find((t) => { return t.teamId === team.teamId; });

        amendTeam.showWeekly = !amendTeam.showWeekly;
        amendTeam.showMonthly = !amendTeam.showMonthly;

        //refresh weekly data
        if (amendTeam.showWeekly) {
            this.setState({ teamListProps });
        }

        //refresh monthly data
        if (amendTeam.showMonthly) {
            // data already served
            // toggle chart on state
            if (amendTeam.teamMonthlyDetails !== null) {
                this.setState({ teamListProps });
                return;
            }
            MoodService.getTeamPeriodMood(team.teamId, 29).then(
                teamResponse => {
                    amendTeam.teamMonthlyDetails = teamResponse.data;
                    this.setState({ teamListProps });
                },
                error => { }
            );
        }
    }

    componentDidMount() {

        if (!AuthService.isSessionValid()) {
            window.location.pathname = "/login";
        }

        /*
        Get your own mood
        */
        MoodService.getWeeklyMood().then(
            response => {
                this.setState({ data: response.data });

            },
            error => {
                this.setState({ data: null });
            }
        );
    }

    render() {

        return (
            <div>
                <div className="categoryDisplay">
                    <h4>
                        Your mood
                    </h4>
                </div>
                <div className="mainContainer">

                    <div className="column">

                        <div className="widget">
                            {this.state.showWeekly &&
                                <WeeklyMoodChart id="thisweek" chartTitle="WEEKLY MOOD" data={this.state.data} membersCount={10} />
                            }
                            {this.state.showMonthly &&
                                <MonthlyMoodChart id="mymonth"
                                    chartTitle="MONTHLY MOOD | 30 days"
                                    data={this.state.monthlyDetails}
                                    userOnly={true} />
                            }
                            <div className="yourViews">
                                <div className={this.state.showWeekly ? "activeDay" : "availableDay"} onClick={() => this.selectIndividual()}>7d</div>
                                <div className={this.state.showMonthly ? "activeDay" : "availableDay"} onClick={() => this.selectIndividual()}>30d</div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <MyStreakdWidgets id="streakWidget" />
                        <div>&nbsp;</div>
                        <MyYearInReviewWidget />

                    </div>

                </div>

                <MyTimelineComponent id="timelineComponentWidget" />

            </div>
        )
    }
}