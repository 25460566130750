import React from 'react';
import './Footer.css';

const Footer = () => {

    return (
        <div>
            <br/><br/>
            <footer className="footer">
                <nav>
                    <ul>
                        <li><a rel="noreferrer" target="_blank" href="https://www.crankydino.com/privacy-policy/">Privacy Policy</a></li>
                        <li><a href="https://www.crankydino.com">Website</a></li>
                        <li><a href="mailto:hello@crankydino.com">Contact Us</a></li>
                    </ul>
                </nav>
            </footer>
        </div>
    )
}

export default Footer;