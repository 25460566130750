import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_PULSE_API_URL + '/pulse/v1';

function registerMood(mood, moodReason) {
    var message = "";
    // when a reson is specified for that mood
    if (moodReason !== undefined && moodReason.length > 0)
        message = { "mood": mood, "moodReason": moodReason };
    else
        message = { "mood": mood };
    return axios.post(API_URL + '/registerPulse', message, { headers: authHeader() });
}

/**
 * INDIVIDUAL ASKS
 */
function getWeeklyMood() {
    return axios.get(API_URL + '/getWeeklyMood',
        { headers: authHeader() });
}
async function getPeriodMood(period) {
    return axios.get(API_URL + '/getPeriodMood/?p=' + period,
        { headers: authHeader() });
}

/**
* TEAM ASKS
*/
async function getTeamPeriodMood(teamId, period) {
    return axios.get(API_URL + '/getTeamPeriodDaysMood/?teamid=' + teamId + '&p=' + period,
        { headers: authHeader() });
}

function getTeamLastWeekMood(teamId) {
    return axios.get(API_URL + '/getTeamLastWeekMood/?teamid=' + teamId,
        { headers: authHeader() });
}


const MoodService = {
    registerMood,
    getWeeklyMood,
    getPeriodMood,
    getTeamPeriodMood,
    getTeamLastWeekMood

}

export default MoodService;