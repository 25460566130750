import React, { useState, useEffect } from 'react';
import validator from 'validator';
import TeamService from "./team.service";
import { TextField, Button } from '@mui/material';

export default function TeamCreation(props) {

    const [teamDescription, setTeamDescription] = useState('');
    const [teamName, setTeamName] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [teamNameError, setTeamNameError] = useState(false);
    const [teamDescriptionError, setTeamDescriptionError] = useState(false);
    const [errorInForm, setErrorInForm] = useState(false);

    const onTeamDescriptionHandle = function (e) {
        setTeamDescription(e.target.value);
    }

    const onTeamNameHandle = function (e) {
        setTeamName(e.target.value);
    }

    const validateAll = function () {
        setTeamNameError(false);
        setTeamDescriptionError(false);
        setErrorInForm(false);

        if (validator.isEmpty(teamName)) {
            setTeamNameError(true);
            setErrorInForm(true);
        }
        if (validator.isEmpty(teamDescription)) {
            setTeamDescriptionError(true);
            setErrorInForm(true);
        }
    }


    const handleTeamCreation = function (e) {
        e.preventDefault();
        setMessage('');
        setLoading(true);

        validateAll();

        if (!errorInForm) {
            TeamService.saveTeam(-1, teamName, teamDescription).then(() => {
                props.action({ teamId: -1, refresh: true, nextAction: 'listTeams' });
            },
                error => {
                    const resMessage = (error.response && error.response.data && error.response.data.message)
                        || error.message
                        || error.toString();

                    setLoading(false);
                    setMessage(resMessage);

                }
            );
        }
        else {
            setLoading(false);
        }
    }

    const cancelChanges = function (e) {
        setTeamDescription('');
        setTeamName('');
        props.action({ teamId: -1, refresh: true, nextAction: 'listTeams' });
    }

    useEffect(() => {
        //getServiceTeamMembers(props.teamId);
    })//, [props.teamId])

    return (

        <div>
            <div className="categoryDisplay">
                <h4>New Team</h4>
            </div>

            <div className="container">

                <div className='widget'>

                    <div className="form-group">
                        <h4>Create a new Team</h4>
                    </div>
                    <div className="form-group">
                        <TextField
                            required
                            id="teamname"
                            name="teamname"
                            label="Team name"
                            variant='outlined'
                            fullWidth
                            value={teamName}
                            onChange={onTeamNameHandle}
                        />
                        {teamNameError &&
                            <div className="alert alert-danger" role="alert">
                                This field is required!
                            </div>
                        }
                    </div>
                    <div className="form-group">


                        <TextField
                            required
                            id="teamdescription"
                            name="teamdescription"
                            label="Team description"
                            variant='outlined'
                            fullWidth
                            value={teamDescription}
                            onChange={onTeamDescriptionHandle} />
                        {teamDescriptionError &&
                            <div className="alert alert-danger" role="alert">
                                This field is required!
                            </div>
                        }
                    </div>

                    <div className="editRowContainer">
                        
                        <div className="editTeamRowItem">
                            <Button
                                disableElevation 
                                variant="contained"
                                onClick={cancelChanges}
                                disabled={loading}
                            >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Cancel</span>
                            </Button>
                        </div>
                        <div className="editTeamRowItem">

                            <Button
                                variant="contained" disableElevation color="primary"
                                onClick={handleTeamCreation}
                                disabled={loading}
                            >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Create Team</span>
                            </Button>
                        </div>
                        
                    </div>


                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
}