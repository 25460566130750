import React from 'react';
import { useNavigate } from 'react-router-dom';
import './MyYearInReviewWidget.css';
import HalfDoughnutChart from '../components/HalfDougnutChart';


const MyYearInReviewWidget = (props) => {
    const navigate = useNavigate();


    function navigateToMyYearInReview() {
        navigate("/myyearinreview");
    }

    return (
        <div>
            <div className='myStreakWidgets'>
                <div className="widgetSmall widgetSmallItem">
                    <div className='myYearInReviewTitle'>
                        <div className="categoryText half">Monthly mood</div>
                        <div className="categoryTextStrongRight half" onClick={navigateToMyYearInReview}>
                            view more
                        </div>
                    </div>
                    <HalfDoughnutChart />
                </div>
                <div>&nbsp;</div>
            </div>
        </div>
    )
}

export default MyYearInReviewWidget;