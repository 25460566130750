import React, { useState, useEffect } from 'react';
import './MyMood.css';
import DailyNotificationService from "./dailyNotification.service";
import MoodService from "./mood.service";
import Smiles from "./Smiles/Smiles";
// import MoodReference from '../services/mood.references';
// import HowAreYouFeeling from './HowAreYouFeeling/HowAreYouFeeling';
import LockIcon from '@mui/icons-material/Lock'
import { Paper, TextField, Button, Grid } from "@mui/material";
import { useParams } from 'react-router-dom';

const MyMood = () => {
    let { notificationToken } = useParams();
    let { moodRecord } = useParams();
    let { moodDate } = useParams();

    const [state, setState] = useState({
        content: "",
        notificationToken: notificationToken,
        moodRecord: moodRecord,
        moodDate: moodDate,
        moodMessage: '',
        loading: false,
        errorMessage: '',
        showSave: true,
        showDashboard: false
    });

    const handleComment = (e) => {
        setState(prevState => ({
            ...prevState,
            moodMessage: e.target.value
        }));
    };

    const onClickVoteCallback = (mood) => {
        if (state.showSave)
            setState(prevState => ({ ...prevState, 'moodRecord': mood }));
    };

    const showDashboard = () => {
        window.location.pathname = '/mydashboard';
    };

    useEffect(() => {
        // Logic previously in componentDidMount
    }, []);

    const sendMood = () => {
        const { notificationToken, moodRecord, moodMessage, moodDate } = state;

        const updateStateOnSuccess = () => {
            setState({
                errorMessage: 'Mood saved. Check your team on the dashboard. Have a nice day!',
                loading: false,
                showSave: false,
                showDashboard: true
            });
            showDashboard();
        };

        const updateStateOnError = (error) => {
            const errorMessage = error.response?.data?.message || error.message;
            setState({ loading: false, errorMessage });
        };

        setState({ loading: true, errorMessage: '' });

        if (moodRecord < 0 || moodRecord > 4 || moodRecord === undefined) {
            setState({ loading: false, errorMessage: 'Hum... That mood might be something off...' });
            return;
        }

        const promise = window.location.pathname === '/user' ?
            MoodService.registerMood(moodRecord, moodMessage) :
            DailyNotificationService.sendMood(notificationToken, moodRecord, moodMessage, moodDate);

        promise.then(updateStateOnSuccess).catch(updateStateOnError);
    };

    // const getStatus = () => {
    //     var message = "What made you have an '{mood}' day?"
    //     if ((state.moodRecord === undefined) || (isNaN(state.moodRecord)))
    //         return "What made your day?";

    //     var moodRef = MoodReference();

    //     //run every property to find moodRecord value
    //     for (var mood in moodRef) {
    //         if (moodRef[mood].value === state.moodRecord) {
    //             //return description
    //             message = message.replace("{mood}", moodRef[mood].description);
    //         }
    //     }
    //     return message;
    // };

    return (
        <div className="container">
            <Paper className="widgetCollectFeedback">

                <div>
                    <h4>
                        <strong>How are you feeling today? </strong>
                    </h4>
                </div>

                <Smiles moodRecordCallback={onClickVoteCallback} />

                {/* <HowAreYouFeeling/> */}

                {state.showSave ?
                    <>
                        <div className="categoryText">
                            <LockIcon /> daily note
                        </div>
                        <div>
                            <TextField
                                placeholder="Here you can jot down thoughts, events, or the reasons you feel in a certain way, providing context to your emotional state."
                                fullWidth
                                multiline
                                margin="normal"
                                variant="outlined"
                                inputProps={{ maxLength: 255 }}
                                onChange={handleComment}
                                InputLabelProps={{
                                    shrink: true
                                }}></TextField>
                        </div>
                        <div style={{ textAlign: 'center' }} id="saveButton">
                            <Grid container>
                                <Grid item xs></Grid>
                                <Grid item xs={6}>
                                    <Button variant="contained" fullWidth color="primary" size="medium"
                                        disabled={state.loading}
                                        onClick={() => sendMood()}>Send</Button>

                                    {state.loading && (
                                        <div className="text-center">
                                            <div className="spinner-border spinner-border-grow-m" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs></Grid>

                            </Grid>
                        </div>
                    </> : null}



                {state.errorMessage !== undefined && state.errorMessage.length > 0 &&
                    <div style={{ textAlign: 'center' }}>
                        <br />
                        {state.errorMessage}
                    </div>
                }

            </Paper>

        </div>
    );
};

export default MyMood;