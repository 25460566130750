import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_MYYEARINREVIEW_API_URL + '/myyearinreview/v1';

async function getMyYearInReview(year) {
    return axios.get(API_URL + '/getReview/' + year,
        {
            headers: authHeader()
        });
}
async function getAvailableYears() {
    return axios.get(API_URL + '/getAvailableYears',
        {
            headers: authHeader()
        });
}

async function getMonthlyCount() {
    return axios.get(API_URL + '/getMonthlyCount',
        {
            headers: authHeader()
        });
}

const MyYearInReviewService = {
    getMyYearInReview,
    getAvailableYears,
    getMonthlyCount
}

export default MyYearInReviewService;