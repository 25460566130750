import React, { Component } from 'react';
import './MyTimelineComponent.css';
import FeedbackService from "./feedback.service";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import LockIcon from '@mui/icons-material/Lock';

import { ReactComponent as Mad } from '../../images/svg/mad.svg';
import { ReactComponent as NotHappy } from '../../images/svg/not happy.svg';
import { ReactComponent as Medium } from '../../images/svg/medium.svg';
import { ReactComponent as Happy } from '../../images/svg/happy.svg';
import { ReactComponent as VeryHappy } from '../../images/svg/very happy.svg';
/*
 * https://stephane-monnot.github.io/react-vertical-timeline/#/
 */
export default class MyTimelineComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: [
                { moodReason: 'If you can see this something is not right', datetime: '2100-12-31 23:59', mood: 'happy' }
            ]
        };
    }

    getIcon(x) {
        switch (x) {
            case 'veryhappy': return <VeryHappy />;
            case 'happy': return <Happy />;
            case 'medium': return <Medium />;
            case 'nothappy': return <NotHappy />;
            case 'mad': return <Mad />;
            default: return '';
        }
    }
    getDescription(x) {
        var mood = '';
        switch (x) {
            case 'veryhappy':
                mood = 'Awesome';
                break;
            case 'happy':
                mood = 'Happy';
                break;
            case 'medium':
                mood = 'OK';
                break;
            case 'nothappy':
                mood = 'Unhappy';
                break;
            case 'mad':
                mood = 'Bad'; 
                break;
            default: 
                mood = 'I\'m confused do we have something new?' + x; 
                break;
        }
        return mood.toUpperCase();
    }
    getDate(x) {
        const unixTime = x;
        const date = new Date(unixTime);
        return date.toLocaleDateString("pt-PT") + ' ' + date.toLocaleTimeString("pt-PT");
    }
    componentDidMount() {
        FeedbackService.getFeedback().then(
            response => {
                this.setState({
                    content: response.data
                });
            },
            error => {
                this.setState({
                    content: (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString()
                });
            }
        );
    }

    render() {
        return (
            <div className='timeline'>

                <div className="categoryDisplay">
                    <h4>
                        <LockIcon /> Daily notes timeline (last 30)
                    </h4>
                </div>
                {this.state.content.length === 0 &&
                    <div>Nothing to see here yet. Try to write a feedback about your day next time you send a mood.</div>
                }
                {this.state.content.length > 0 &&
                    <div>
                        <VerticalTimeline id="myVerticalTimeline">
                            {this.state.content.map((x, i) => {
                                let contStyle;
                                let contArrowStyle;
                                let contDateClassname;

                                //the last feedback gets a different color
                                if (i === 0) {
                                    contStyle = { background: '#89CFF0', color: '#000' };
                                    contArrowStyle = { borderRight: '7px solid  #89CFF0' };
                                    contDateClassname = "dateClassname";
                                }


                                return (
                                    <VerticalTimelineElement key={i}
                                        className="vertical-timeline-element--work"
                                        contentStyle={contStyle}
                                        contentArrowStyle={contArrowStyle}
                                        date={this.getDate(x.datetime)}
                                        dateClassName={contDateClassname}
                                        iconStyle={{ background: '#ffffff', color: '#fff' }}
                                        icon={this.getIcon(x.mood)}

                                    >
                                        <h4 className="vertical-timeline-element-title">
                                            {this.getDescription(x.mood)}</h4>
                                        <p className='timelineDescriptionStyle'>
                                            {x.moodReason}
                                        </p>
                                    </VerticalTimelineElement>
                                )
                            }
                            )}

                        </VerticalTimeline>
                    </div>
                }

            </div>
        )
    }

}