import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from '@mui/material';
import './Smiles.css';
import { ReactComponent as Mad } from '../../images/svg/mad.svg';
import { ReactComponent as NotHappy } from '../../images/svg/not happy.svg';
import { ReactComponent as Medium } from '../../images/svg/medium.svg';
import { ReactComponent as Happy } from '../../images/svg/happy.svg';
import { ReactComponent as VeryHappy } from '../../images/svg/very happy.svg';
import MoodReference from '../../services/mood.references';

const Smiles = ({moodRecordCallback}) => {
    
    let {notificationToken} = useParams();
    let {moodRecord} = useParams();
    let {moodDate} = useParams();
    
    const [state, setState] = useState({
        content: "",
        notificationToken: notificationToken,
        moodRecord: moodRecord,
        moodDate: moodDate,
        moodMessage: '',
        loading: false,
        errorMessage: '',
        showSave: true,
        showDashboard: false
    });

    const getMoodClass = (mood) => {
        return state.moodRecord === mood ? "moodIcon moodIconSelected" : "moodIcon";
    };


    useEffect(() => {
        
    }, []);
    
    const onClickVote = (mood) => {
        if (state.showSave){
            setState(prevState => ({ ...prevState, 'moodRecord': MoodReference()[mood].value }));
            moodRecordCallback(MoodReference()[mood].value);
        }
            
    };

    const moodRef = MoodReference();
    return (
        <div>
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                height: '250px'
            }} >

                <div className={getMoodClass(moodRef.bad.value)}>
                    <Mad className="svgIcon" alt={moodRef.bad.description} onClick={() => onClickVote('bad')} />
                    <Typography>{moodRef.bad.description}</Typography>
                </div>
                <div className={getMoodClass(moodRef.unhappy.value)}>
                    <NotHappy className="svgIcon" alt={moodRef.unhappy.description} onClick={() => onClickVote('unhappy')} />
                    <Typography>{moodRef.unhappy.description}</Typography>
                </div>
                <div className={getMoodClass(moodRef.medium.value)}>
                    <Medium className="svgIcon" alt={moodRef.medium.description} onClick={() => onClickVote('medium')} />
                    <Typography>{moodRef.medium.description}</Typography>
                </div>
                <div className={getMoodClass(moodRef.happy.value)}>
                    <Happy className="svgIcon" alt={moodRef.happy.description} onClick={() => onClickVote('happy')} />
                    <Typography>{moodRef.happy.description}</Typography>
                </div>
                <div className={getMoodClass(moodRef.awesome.value)}>
                    <VeryHappy className="svgIcon" alt={moodRef.awesome.description} onClick={() => onClickVote('awesome')} />
                    <Typography>{moodRef.awesome.description}</Typography>
                </div>
            </div>

        </div>
    )
}

export default Smiles;