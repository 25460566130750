import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_FEEDBACK_API_URL + '/feedback/v1';


async function registerFeedback(teamId, text, extensionAddress) {
    return axios.post(API_URL + '/registerTeamFeedback/?teamid=' + teamId,
        { 'text': text, 'extensionAddress': extensionAddress },
        { headers: authHeader() });
}

async function getTeamFeedback(teamId) {
    return axios.get(API_URL + '/getTeamFeedback/?teamid=' + teamId,
        { headers: authHeader() });
}

const FeedComponentService = {
    registerFeedback,
    getTeamFeedback
}

export default FeedComponentService;
