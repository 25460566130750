import axios from 'axios';
import authHeader from '../../services/auth-header';

const API_URL = process.env.REACT_APP_FEEDBACK_API_URL + '/feedback/v1';


/**
 * INDIVIDUAL FEEDBACK
 */
function getFeedback() {
    return axios.get(API_URL + '/getFeedback',
        { headers: authHeader() });
}


const FeedbackService = {
    getFeedback
}
export default FeedbackService;