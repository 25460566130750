import React, { useState } from 'react';
import TeamService from "./team.service";
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';

export default function TeamEditDetails(props) {
    const [teamDescription, setDescription] = useState(props.teamDescription);
    const [teamName, setName] = useState(props.teamName);

    const getServiceTeamMembers = async (teamId) => {

        await TeamService.getTeamMembers(teamId)
            .then(response => {

            },
                error => {
                    this.setState({
                        message: (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString()
                    });

                });


    }
    const cancelChanges = function (e) {
        props.action({ teamId: -1, refresh: true, nextAction: 'listTeams' });
    }
    const saveChanges = function (e) {
        //console.log('saveChanges');
        e.preventDefault();
        //setMessage('');
        //setLoading(true);

        //validateAll();

        //if (!errorInForm) {
        TeamService.saveTeam(props.teamId, teamName, teamDescription).then(() => {
            props.action({ teamId: -1, refresh: true, nextAction: 'listTeams' });
        },
            error => {
                // const resMessage = (error.response && error.response.data && error.response.data.message)
                //     || error.message 
                //     || error.toString();

                //            setLoading(false);
                //            setMessage(resMessage);

            }
        );
        // }
        // else {
        //     setLoading(false);
        // }

    }
    const handleNameChange = function (e) {
        setName(e.target.value);
    }
    const handleDescriptionChange = function (e) {
        setDescription(e.target.value);
    }

    React.useEffect(() => {
        getServiceTeamMembers(props.teamId);
    }, [props.teamId])

    return (
        <div>
            <div className="categoryDisplay">
                <h4>Edit Team:</h4>
            </div>

            <div className="columns alignmentContainer" >
                <div className="rowContainer itemSpacing">
                    <TextField
                        className='edittextBox'
                        label="Team Name"
                        type="text"
                        variant='outlined'
                        name="teamName"
                        onChange={handleNameChange}
                        value={teamName} />
                </div>
                <div className="rowContainer itemSpacing">
                    <TextField
                        className='edittextBox'
                        label="Team Description"
                        fullWidth
                        variant='outlined'
                        type="text"
                        name="teamDescription"
                        onChange={handleDescriptionChange}
                        value={teamDescription} />
                </div>
            </div>

            <div className="editRowContainer">

                <div className='editTeamRowItem'>
                    <Button variant="contained" onClick={(e) => cancelChanges()}>Cancel Changes</Button>
                </div>
                
                <div className='editTeamRowItem'>
                    <Button variant="contained" color="primary" onClick={saveChanges}>Save Changes</Button>
                </div>

            </div>

        </div>

    );
}