import React, { Component } from 'react';
import './SideBarMenu.css';

export default class SideBarMenu extends Component {
    constructor(props){
        super(props);
        this.state ={
            data:props.data
        }
    }

    render() {
        const {data} = this.state;
        
        return (
            <div className='SideBar'>
                <ul className='SideBarList'>{data !== undefined && data.map((val, key) => {
                    return (
                        <li key={key} className="row" id={window.location.pathname === val.link ? "active" : ""}
                            onClick={() => {
                                window.location.pathname = val.link;
                            }}>
                            <div id="icon">{val.icon}</div>
                            <div id="title">{val.title}</div>
                        </li>
                    )
                })}
                </ul>
            </div>
        )
    }


}