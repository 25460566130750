import axios from 'axios';
import authHeader from '../services/auth-header';

const INVITE_API_URL = process.env.REACT_APP_INVITE_API_URL + '/invite/v1';


async function joinByActivationCode(activationKey, email, name, password) {
    return await axios
        .post(INVITE_API_URL + "/joinByActivationCode", {
            a: activationKey,
            e: email,
            p: password,
            n: name
        });
}

async function inviteTeamMembers(body) {
    return axios.post(INVITE_API_URL + '/inviteTeamMembers',
        body,
        { headers: authHeader() });
}


const InviteService = {
    joinByActivationCode,
    inviteTeamMembers
}

export default InviteService;