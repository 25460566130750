import React, { useEffect, useState } from 'react';
import ActivationService from "./activation.service";
import '../login/Login.css';
import { Button, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Buffer } from 'buffer';

export default function ActivateAccount(props) {

    let { activationKey } = useParams();


    const [activationCode, setActivationCode] = useState('');
    const [email, setEmail] = useState('');
    const [showLogin, setShowLogin] = useState(false);

    const handleActivationCodeChange = function (e) {
        setActivationCode(e.target.value);
    }
    const handleEmailChange = function (e) {
        setEmail(e.target.value);
    }

    const handleAccountActivation = function (e) {

        ActivationService.activateAccount(activationCode, email)
            .then(response => {
                setShowLogin(true);
            }
                , error => {
                    //console.log(error.response.data.message);
                }
            );
    }
    const handleGoToLogin = function () {
        window.location.pathname = '/login';
    }

    useEffect(() => {

        if (activationKey === undefined || activationKey === "") return;
        try {
            let base64ToString = Buffer.from(activationKey, "base64").toString();
            var myObject = JSON.parse(base64ToString);
            setActivationCode(myObject.a);
            setEmail(myObject.e);
        }
        catch (e) {
            console.log(e);
        }
    }, [activationKey])


    return (

        <div className="widget">
            <div className="loginComponent">


                <div className='loginComponentSize'>

                    <div className='backgroundImage' />

                    <div className='text-center'>
                        <span className="crankyName">Activation</span>
                    </div>

                    {!showLogin &&
                        <>
                            <><br /></>
                            <div >
                                <TextField label="Activation Code" onChange={handleActivationCodeChange} value={activationCode}></TextField>
                            </div>

                            <><br /></>
                            <div>
                                <TextField fullWidth label="E-mail Address" onChange={handleEmailChange} value={email}></TextField>
                            </div>

                            <><br /></>

                            <div className="text-center">
                                <Button variant="contained" color="primary" onClick={handleAccountActivation}>Activate Account</Button>
                            </div>
                        </>
                    }
                    {showLogin &&
                        <>

                            <div className="text-center">
                                <><br /></>
                                <div>Account successfuly activated.</div>
                                <><br /></>
                                <Button variant="contained" color="primary" onClick={handleGoToLogin}>Go To Login</Button>
                            </div>
                        </>
                    }
                </div>

            </div>
        </div >
    )
}
