import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_TEAMMANAGEMENT_API_URL + '/team/v1';

function getTeams() {
    return axios.get(API_URL + '/getTeams', { headers: authHeader() });
}

async function saveTeam(teamId, teamName, teamDescription) {

    var message;
    if (teamId > -1) {
        message = {
            teamId,
            teamName,
            teamDescription
        }
    }
    else {
        message = {
            teamName,
            teamDescription
        }

    };
    const response = await axios
        .post(API_URL + '/saveTeam',
            message,
            { headers: authHeader() });

    if (response.data.team) {
        localStorage.setItem("team", JSON.stringify(response.data.team));
    }
    return response.data.team;
}

async function deleteTeam(teamId) {
    await axios
        .delete(API_URL + '/deleteTeam',
            {
                headers: authHeader(),
                data: {
                    teamId
                }
            }
        );
    return true;
}

async function getTeamMembers(teamId) {
    return axios.get(API_URL + '/getTeamMembers/?teamid=' + teamId,
        {
            headers: authHeader()
        });
}


const TeamService = {
    getTeams,
    saveTeam,
    deleteTeam,
    getTeamMembers

}

export default TeamService;