import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';


export default function AddTeamMember(props) {
    const [open, setOpen] = useState(false);
    const [personName, setPersonName] = useState('');
    const [personEmail, setPersonEmail] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const onPersonNameChange = function (e) {
        setPersonName(e.target.value);
    }

    const onPersonEmail = function (e) {
        setPersonEmail(e.target.value);
    }

    const handleInvite = () => {
        props.event(personName, personEmail);
        setPersonEmail('');
        setPersonName('');
        setOpen(false);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>

            <Button onClick={handleClickOpen}>
                <Icon className="addButton">add_circle</Icon>
                <div>
                    member
                </div>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">ADD MEMBER TO TEAM</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To invite to this team, please enter name and e-mail address here. We will send an activation e-mail.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        onChange={onPersonNameChange}
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        onChange={onPersonEmail}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleInvite} color="primary">
                        Invite
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
