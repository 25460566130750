import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_TEAMMANAGEMENT_API_URL + '/team/v1';

async function getMyTeams() {
    return axios.get(API_URL + '/getMyTeams',
        {
            headers: authHeader()
        });
}

const TeamService = {
    getMyTeams
}

export default TeamService;