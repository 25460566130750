import * as d3 from "d3";
import "d3-time-format";
import React, { useCallback, useEffect } from "react";
import './WeeklyMoodChart.css';


const NOW_CONSTANT = "Now";
const LASTWEEK_CONSTANT = "Last Week";

const WeeklyMoodChart = (props) => {

  /*
  Get Mood Image
  */
  function getUrlForMood(d) {
    var mapper = {
      0: '/svg/mad.svg',
      1: '/svg/not%20happy.svg',
      2: '/svg/medium.svg',
      3: '/svg/happy.svg',
      4: '/svg/very%20happy.svg'
    }
    return mapper[d];
  }

  function getWeekDays(d) {
    var mapper = {
      0: 'Monday',
      1: 'Tuesday',
      2: 'Wednesday',
      3: 'Thursday',
      4: 'Friday',
      5: 'Saturday',
      6: 'Sunday'
    }
    return mapper[d];
  }

  function getYAxisNames(d) {
    return "";
    // var mapper = {
    //   0: MoodReference().veryunpleasant.description,
    //   1: MoodReference().unhappy.description,
    //   2: MoodReference().medium.description,
    //   3: MoodReference().happy.description,
    //   4: MoodReference().awesome.description
    // }
    // return mapper[d]
  }

  function mapMoodArray(dataIn, labelToMatch) {
    const moodArray = dataIn.find(element => element.label === labelToMatch);
    if (moodArray === undefined) return [];

    var arrayOut = [];
    moodArray.data.forEach((d, i) => {
      if (!isNaN(d)) {
        arrayOut.push({ "mood": d, "moodDay": i, "count": moodArray.feedbackCount[i] });
      }
    });

    return arrayOut;
  }

  /*
  CHART FOR WEEKLY MOOD
  */

  const createGraph = useCallback(async () => {

    /**
     * Calcula o tamanho da bola máximo em linha com o total de feedback para o dia
     * @param {feedback do dia} x 
     * @returns 
     */
    function sizeR(x) {
      return (x.count / (props.membersCount)) * 50;
    }

    const margin = { top: 40, right: 20, bottom: 40, left: 70 },
      width = 560 - margin.left - margin.right,
      height = 300 - margin.top - margin.bottom;


    const x = d3.scalePoint().range([0, width]);
    const y = d3.scaleLinear().range([height, 0]);

    const areaLine = d3.area()
      .defined(d => !isNaN(d.mood))
      .curve(d3.curveMonotoneX)
      .x(function (d) { return x(d.moodDay) })
      .y(function (d) { return y(d.mood) })

    const svg = d3.select("#" + props.id).select(".svgChart")
      .attr("viewBox", "0 0 " + (width + margin.left + margin.right) + " " + (height + margin.top + margin.bottom))
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);


    if (props.data == null) return;


    var dayData = mapMoodArray(props.data, NOW_CONSTANT);
    var weekData = mapMoodArray(props.data, LASTWEEK_CONSTANT);

    x.domain([0, 1, 2, 3, 4, 5, 6]).range([0, width]);
    y.domain([0, 4]).range([height, 0]);

    svg.append("path")
      .datum(weekData)
      .attr("class", "lastWeekPath")
      .attr("d", areaLine);

    // horizontal labels
    svg.append("g")
      .attr("transform", `translate(0, ${height})`)
      .attr("class", "axisHorizontal")
      .call(
        d3.axisBottom(x)
          .tickValues([0, 1, 2, 3, 4, 5, 6])
          .tickFormat(getWeekDays)
      )
      .selectAll("text")
      .style("font-size", 10)
      .style("fill", "#69a3b2");

    // vertical labels
    svg.append("g")
      .attr("class", "axisVertical")
      .call(
        d3.axisLeft(y)
          .tickValues([0, 1, 2, 3, 4])
          .tickFormat(getYAxisNames)
      )
      .selectAll(".tick").each(function (d, i) {
        d3.select(this)
          .append('image')
          .attr('xlink:href', getUrlForMood(i))
          .attr('x', 0 - 55)
          .attr('y', margin.bottom - 57)
          .attr('width', 32)
          .attr('height', 32);
      });
      //.selectAll("text")
      //.attr("transform", "translate(-40,25)rotate(0)")
      //.style("text-anchor", "start")
      // .style("font-size", 10)
      //.style("fill", "#69a3b2");


    // Vertical Legend
    svg.append("text")
      .attr("y", -25)
      .attr("x", -margin.left)
      .attr("class", "title")
      .text(props.chartTitle)
      .style("fill", "#69a3b2")
      .style("font-weight", "500");



    svg.append("path")
      .datum(dayData)
      .attr("class", "dayPath")
      .attr("d", areaLine);

    if (!isNaN(props.membersCount)) {
      svg
        .append("g")
        .selectAll("dot")
        .data(dayData)
        .enter()
        .append("circle")
        .attr("class", "myCircle")
        .attr("cx", function (d) { return x(d.moodDay) })
        .attr("cy", function (d) { return y(d.mood) })
        .attr("r", function (d) { return sizeR(d) })
        .attr("stroke", "#69b3a2")
        .attr("stroke-width", 1)
        .attr("fill", "orange")
        .attr("opacity", .7);
    }

    //Legend
    svg.append("circle").attr("cx", width).attr("cy", -33).attr("r", 6).style("fill", "orange");
    svg.append("circle").attr("cx", width).attr("cy", -17).attr("r", 6).style("fill", "#9ccdc1").style("opacity", ".2").style("stroke", "#000");
    svg.append("text").attr("x", width - 80).attr("y", -33).text("This week").style("font-size", "15px").attr("alignment-baseline", "middle");
    svg.append("text").attr("x", width - 80).attr("y", -17).text("Last week").style("font-size", "15px").attr("alignment-baseline", "middle");
  }, [props.data, props.chartTitle, props.id, props.membersCount])

  useEffect(() => {
    if (props.data !== undefined && props.data != null) {
      createGraph();
    }
  }, [createGraph, props.data, props.chartTitle, props.id, props.membersCount])
  return (

    <div>
      {props.id !== undefined && props.id.length > 0 &&
        <div className="weeklyMoodChart" id={props.id}>
          <svg className="svgChart" />
        </div>
      }

      {/* HELP ME NOT TO FORGET ABOUT ID PROPERTY */}
      {((props.id === undefined || props.id.length === 0) &&
        <div>
          You need to set the property "id" to this Mood Chart component.
        </div>)}

      {/* HELP ME NOT TO FORGET ABOUT DATA PROPERTY */}
      {((props.data === undefined || props.data == null) &&
        <div>
          Loading chart information...
        </div>)}

    </div>

  )

}

export default WeeklyMoodChart;