import axios from 'axios';
import authHeader from '../services/auth-header';

const API_URL_NOTIFICATION = process.env.REACT_APP_NOTIFICATION_API_URL + '/notification/v1';

    async function getNotificationDetails() {
        return axios.get(API_URL_NOTIFICATION + "/getNotificationDetails", { headers: authHeader() });
    }
    function setNotificationStatus(notificationStatus) {
        return axios.post(API_URL_NOTIFICATION + "/setNotificationStatus", notificationStatus, { headers: authHeader() });
    }
    function setWeekendStatus(weekendsStatus) {
        return axios.post(API_URL_NOTIFICATION + "/setWeekendStatus", weekendsStatus, { headers: authHeader() });
    }
    function setNotificationHour(time) {
        return axios.post(API_URL_NOTIFICATION + "/setNotificationHour", time, { headers: authHeader() });
    }

const NotificationService = {
    getNotificationDetails,
    setNotificationStatus,
    setWeekendStatus,
    setNotificationHour
}

export default NotificationService;