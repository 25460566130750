import React, { useEffect, useState } from 'react';
import './MyStreakWidgets.css';
import StreakService from "./streak.service";


const MyStreakWidgets = () => {
    const [content, setContent] = useState(null);

    useEffect(() => {
        StreakService.getStreak().then(
            response => {
                setContent(response.data);
            },
            error => {

                setContent((error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString())
            }
        );
    }, []);


    return (
        <div>
            {((content && content.lastStreakNumber > 1) || (content && content.highestStreakNumber > 1)) &&
                <div className='myStreakWidgets'>

                    <div className="widgetSmall widgetSmallItem">
                        <div className="categoryText">Streaks</div>
                        <div>
                            <div><strong>CURRENT STREAK:</strong> #{content.lastStreakNumber} days in a row</div>
                            <div>
                                <strong>MAX STREAK:</strong> #{content.highestStreakNumber} days in a row ({content.highestStreakDate})</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default MyStreakWidgets;