import React, { Component } from "react";
import MoodService from "./mood.service";
import TeamService from "./team.service";
import './DashboardChart.css';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import AuthService from "../login/auth.service";
import TeamWidget from "./TeamWidget";


export default class TeamsDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: "",
            data: null,
            teamList: [],
            loading: true,
            showWeekly: true,
            showMonthly: false,
            monthlyDetails: null,
            moderatorProfile: false
        }
    }


    componentDidMount() {
        if (!AuthService.isSessionValid())
            return;

        const user = AuthService.getCurrentUser();
        this.setState({ moderatorProfile: user.roles.includes("ROLE_MODERATOR") });

        /*
        Get your own mood
        */
        MoodService.getWeeklyMood().then(
            response => {
                this.setState({ data: response.data });

            },
            error => {
                this.setState({ data: null });
            }
        );

        /*
        Get all teams you belong
         */
        TeamService.getMyTeams().then(
            response => {

                if (response.data.team.length === 1) {
                    this.teamDetails(response.data.team[0]);
                    return;
                }
                this.setState({ teamList: response.data.team });
            },
            error => {

            }
        );


    }


    /*
     * Call team details 
    */
    teamDetails(team) {
        window.location.pathname = '/team/' + team.teamId;
    }

    /**
     * Opens management teams controller
     */
    manageTeams() {
        window.location.pathname = '/manageteams';
    }



    render() {

        return (

            <div>
                <div className="row">
                    <div className="col-md-6 categoryDisplay">
                        <h4>
                            {"Team mood (" + this.state.teamList.length + ")"}
                        </h4>
                    </div>
                    <div className="col-md-6 categoryDisplay text-right">
                        {this.state.moderatorProfile &&
                            <Button onClick={(e) => this.manageTeams()}>
                                <Icon className="addButton">manage_accounts</Icon>
                                <div>
                                    manage teams
                                </div>
                            </Button>
                        }
                    </div>

                </div>

                <div className="row">
                    <div className="chartRow">
                        {
                            this.state.teamList && this.state.teamList[0] !== undefined && this.state.teamList.sort((a, b) => { return a.teamName > b.teamName ? -1 : 1; })
                                .map((team) =>
                                    <div key={team.teamId}>
                                        <div className="widget" >
                                            <TeamWidget teamid={team.teamId} teamname={team.teamName} teammembers={team.teamMembers} showdetails={true}/>
                                        </div>

                                        <div>
                                            &nbsp;
                                        </div>
                                    </div>
                                )
                        }
                    </div>

                    {/* WHEN NO TEAMS ARE FOUND */}
                    {this.state.teamList && this.state.teamList.length === 0 &&
                        <div className="row">
                            <div>
                                No teams created yet.
                            </div>
                        </div>
                    }
                </div >

            </div >
        )
    }
}